<template>
  <svg-icon-qouting class="help_topic" @click.stop="showModal = true" color="#2980B9" data="far fa-question-circle" />
  <Modal class="help_topic_modal" v-model="showModal"  @click.stop>
    <iframe class="help_topic_modal__frame"
        v-show="showModal"
        :src="iframeDataWithParam"
        @load="loadData = true"
    />
    <div class="help_topic_modal__loader" v-if="!loadData">
      <Loader />
    </div>
  </Modal>
</template>

<script>
import { ref , watch, computed} from 'vue'
import Modal from "../elements/Modal";
import Loader from "./Loader";
import {defaultStyleColors} from "@/components/item-wizard/store/constant";

export default {
name: "HelpTopic",
  components: {
    Modal,
    Loader
  },
  props: ['iframeData'],
  setup(props) {
    const loadData = ref(false)
    const showModal = ref(false)


    const iframeDataWithParam = computed(() => {
      let branding = sessionStorage.getItem('branding')
      let styles = defaultStyleColors
      if (branding) styles = JSON.parse(branding)

      const primary = styles.filter(i => i.setting == 'LayoutIconPrimary')[0].value
      const secondary = styles.filter(i => i.setting == 'SecondaryColour')[0].value
      const paragraphColour = styles.filter(i => i.setting == 'SecondaryTextColour')[0].value
      const staticColour = styles.filter(i => i.setting == 'LayoutIconSecondary')[0].value

      return props.iframeData + '/?primary-colour=' + primary.replace('#', '') +
          '&secondary-colour=' + secondary.replace('#', '') +
          '&paragraph-colour='+ paragraphColour.replace('#', '') +
          '&static-colour=' + staticColour.replace('#', '') }
      )

    return {
      showModal,
      loadData,
      iframeDataWithParam
    }
  }
}
</script>

<style scoped lang="scss">
  .help_topic {
    cursor: pointer;
  }
  .help_topic_modal {
    &.modal-wrapper {
      min-width: 100% !important;
    }

    &__frame {
      width: 100%;
      height: 100%;
    }
    &__loader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep svg {
        font-size: 40px;
      }
    }
    ::v-deep .modal {
      height: 100%;
      width: 768px;
      @media(max-width: 768px) {
        width: 100%;
      }
    }
  }
</style>
